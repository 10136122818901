import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import firebase from 'firebase/app';
import 'firebase/firestore';
import LogViewer from '../components/LogViewer'

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCgD0y67qvY90yEhS90qAcA1ch_M4i74wo",
    authDomain: "monocle-21465.firebaseapp.com",
    projectId: "monocle-21465",
    storageBucket: "monocle-21465.appspot.com",
    messagingSenderId: "577484496710",
    appId: "1:577484496710:web:e7577bd863dd003f2b4f47",
    measurementId: "G-K9M37VSGH8"
  });
}


const firestore = firebase.firestore();


const useStyles = makeStyles((theme) => ({
  viewerContainer: {
    marginTop: 16,
    maxHeight: 'calc(100vh - 120px)',
    overflow: 'auto'
  }

}));



export default function Logs() {

  const classes = useStyles();

  const [logs, setLogs] = React.useState([]);

  useEffect(() => {

    const sessionRef =
      firestore.collection('logs').doc('scrapingLogs').collection('sessions').orderBy('timestamp', 'desc').limit(1);

    // sessionRef.get().then(snap => {
    sessionRef.onSnapshot(snap => {

      console.log("session changed");

      const logsQuery = snap.docs[0].ref.collection('session').orderBy('lineNumber').limitToLast(1000);
      logsQuery.onSnapshot(snap => {
        console.log("New items added");
        const arr = [];
        snap.docs.map(doc => {
          const data = doc.data();
          arr.push({
            id: doc.id,
            text: data.message,
            ...doc.data()
          });
          return data;
        });
        setLogs(arr);
      })
    })
    return () => {
      // cleanup
    }
  }, [])


  return (
    <div>
      <div className={classes.viewerContainer}>
        <LogViewer
          logs={logs}
        />
      </div>

    </div>
  )
}