import React, { useState } from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { startCase } from 'lodash';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCgD0y67qvY90yEhS90qAcA1ch_M4i74wo",
    authDomain: "monocle-21465.firebaseapp.com",
    projectId: "monocle-21465",
    storageBucket: "monocle-21465.appspot.com",
    messagingSenderId: "577484496710",
    appId: "1:577484496710:web:e7577bd863dd003f2b4f47",
    measurementId: "G-K9M37VSGH8"
  });
}


const firestore = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: theme.typography.fontWeightBold
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  progressContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableRowNew: {
    backgroundColor: '#ffccd9'
  },
  hideButton: {
    marginLeft: '16px'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2),
  },
  accordianDetails: {
    flexDirection: 'column'
  },
  enabled: {
    display: 'inline-block',
    width: 18,
    height: 18,
    borderRadius: 16,
    backgroundColor: 'green',
    marginRight: 12,
    marginTop: 1
  },
  disabled: {
    display: 'inline-block',
    width: 18,
    height: 18,
    borderRadius: 36,
    backgroundColor: 'red',
    marginRight: 12,
    marginTop: 1
  }
}));

export default function Services() {

  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const [state, setState] = useState({ switchState: {} });
  const [isNew, setIsNew] = useState({ isNewState: {} });

  const [open, setOpen] = React.useState(false);
  const [newServiceName, setNewServiceName] = React.useState(null);
  const [newServiceID, setNewServiceID] = React.useState(null);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [alertText, setAlertText] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState('');


  const handleToggleChange = (service, category, i) => (event) => {
    const categoryRef = firestore.collection('services').doc(service.id).collection('categories').doc(category.id);
    let enabled;
    if (event.target.checked) {
      enabled = true;
    }
    if (!event.target.checked) {
      enabled = false;
    }
    try {
      categoryRef.update({
        enabled: enabled,
        isNew: false
      });

      const list = Object.assign({}, state.switchState);

      list['switch-' + i] = event.target.checked;

      setState({
        switchState: list
      })

      firestore.collection('services').doc(service.id).set({
        totalNumberOfEnabledCategories: enabled ? firebase.firestore.FieldValue.increment(1) : firebase.firestore.FieldValue.increment(-1),
        totalNumberOfNewCategories: firebase.firestore.FieldValue.increment(-1)
      }, {
        merge: true
      });
    } catch (error) {
      console.error(error)
    }
  };

  const classes = useStyles();

  const servicesRef = firestore.collection('services');
  const servicesQuery = servicesRef.orderBy('name');
  const [services] = useCollectionData(servicesQuery, { idField: 'id' });

  const handleChange = (panel, service) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      loadCategories(service);
    }
  };

  const hideCategory = (service, item) => (e) => {
    const categoryRef = firestore.collection('services').doc(service.id).collection('categories').doc(item.id);
    categoryRef.update({
      hidden: true,
      isNew: false
    });
    firestore.collection('services').doc(service.id).set({
      totalNumberOfHiddenCategories: firebase.firestore.FieldValue.increment(1),
      totalNumberOfNewCategories: firebase.firestore.FieldValue.increment(-1),
    }, {
      merge: true
    });
    loadCategories(service);

  }

  const loadCategories = async (service) => {
    setLoadingCategories(true);
    const categoriesRef = firestore.collection('services').doc(service.id).collection('categories');
    const categoryQuery = categoriesRef
      .where('hidden', '==', false)
      .orderBy('isNew', 'desc')
      .orderBy('categoryName');

    const list = Object.assign({}, isNew.isNewState);

    let items = [];
    (await categoryQuery.get()).docs.forEach(doc => {
      items.push({
        id: doc.id,
        ...doc.data()
      });
      list['isNew-' + doc.id] = doc.data().isNew;
    });

    setIsNew({
      isNewState: list
    })

    setLoadingCategories(false);

    // sort categories by status (enabled first)

    // initiate switch states
    const switchState = {};

    items.forEach((item, index) => {
      switchState['switch-' + index] = item.enabled === true;
    });
    setState({
      switchState: switchState
    });

    setCategories(items);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const populateServiceObject = (e) => {
    if (e.target?.value?.trim().length < 3) {
      return;
    }
    const newServiceName = startCase(e.target.value.trim().toLowerCase());
    const newServiceID = newServiceName.replace(/\s/g, '-');
    setNewServiceName(newServiceName);
    setNewServiceID(newServiceID);
  }

  const addNewService = async () => {
    if (!newServiceID) {
      return;
    }
    if (!newServiceName) {
      return;
    }

    // check if already in firestore
    const serviceRef = (await firestore.collection('services').where('name', '==', newServiceName).get()).docs;

    if (!serviceRef[0]) {
      // add
      await firestore.collection('services').add({
        id: newServiceID,
        name: newServiceName
      });
      setAlertSeverity('success');
      setAlertText(`${newServiceName} has been added.`);
      setOpenSnackBar(true);
      setOpen(false);
    } else {
      // already exists, notify
      setAlertSeverity('error');
      setAlertText(`${newServiceName} already exists.`);
      setOpenSnackBar(true);
    }

    setNewServiceName(null);
    setNewServiceName(null);
  }

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  }

  const toggleService = async (service) => {
    const serviceRef = (await firestore.collection('services').where('name', '==', service.name).get()).docs;
    const valueToUpdate = !(serviceRef[0].data().isEnabled === true);
    serviceRef[0].ref.set({ isEnabled: valueToUpdate }, { merge: true });
  }

  return (
    <div>

      {services && services.map(service =>
      (<Accordion key={service.id}
        expanded={expanded === `panel${service.id}`}
        onChange={handleChange(`panel${service.id}`, service)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <span className={service.isEnabled === true ? classes.enabled : classes.disabled}></span>
          <Typography className={classes.heading}>{service.name}</Typography>
          <Typography variant="caption" className={classes.secondaryHeading}>{service.totalNumberOfEnabledCategories}/{service.totalNumberOfCategories} {service.totalNumberOfHiddenCategories ? ` (${service.totalNumberOfHiddenCategories} hidden)` : null}
            {
              service.totalNumberOfNewCategories && service.totalNumberOfNewCategories > 0 ?
                <Typography variant='subtitle1' color='secondary'>
                  {service.totalNumberOfNewCategories} new categories detected
                </Typography>
                : null
            }

          </Typography>

        </AccordionSummary>
        <AccordionDetails className={classes.accordianDetails}>
          <div>
            Toggle {service.name}
            <Button onClick={() => toggleService(service)}>
              {
                service.isEnabled ? 'Disable' : 'Enable'
              }
            </Button>
          </div>
          {loadingCategories ? <div className={classes.progressContainer}>
            <CircularProgress color="secondary" />
          </div>
            :
            <TableContainer >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories && categories.map((item, i) => (
                    <TableRow key={item.id}
                      className={isNew.isNewState['isNew-' + item.id] === true ? classes.tableRowNew : ''}
                    >
                      <TableCell component="th" scope="row">
                        {item.categoryName}

                        {item.isNew ? <Button className={classes.hideButton}
                          onClick={hideCategory(service, item)}
                        >Hide</Button> : null}
                      </TableCell>
                      <TableCell align="right">
                        <Switch
                          // checked={item.enabled === true}
                          checked={state.switchState['switch-' + i] === true}
                          onChange={handleToggleChange(service, item, i)}
                          name={item.id}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </AccordionDetails>
      </Accordion>)
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add a new service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <em>Note: This will be used as the search term.
              <br />And capitalize each word.
            </em>
          </DialogContentText>

          <TextField inputProps={{ style: { textTransform: 'capitalize' } }} label="Add a new service" variant="outlined" onInput={populateServiceObject} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={addNewService}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Fab color="secondary" aria-label="add" className={classes.fab} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>

      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
        <MuiAlert onClose={handleSnackBarClose} elevation={6} variant="filled" severity={alertSeverity} >
          {alertText}
        </MuiAlert>
      </Snackbar>


    </div>
  )
}