import React from 'react'
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core'
import { HomeOutlined, OutlinedFlag, DeleteOutlineOutlined, PlaceOutlined, DataUsageOutlined } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';



import firebase from 'firebase/app';
import 'firebase/firestore';

const moment = require('moment-timezone');

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCgD0y67qvY90yEhS90qAcA1ch_M4i74wo",
    authDomain: "monocle-21465.firebaseapp.com",
    projectId: "monocle-21465",
    storageBucket: "monocle-21465.appspot.com",
    messagingSenderId: "577484496710",
    appId: "1:577484496710:web:e7577bd863dd003f2b4f47",
    measurementId: "G-K9M37VSGH8"
  });
}

const firestore = firebase.firestore();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      marginBottom: 26
    },
    page: {
      background: '#f9f9f9',
      width: '100%',
      height: '100%',
      minHeight: '100vh',
      padding: theme.spacing(3),
      boxSizing: 'border-box'
    },
    drawer: {
      width: drawerWidth,
      backgroundColor: '#ececec'
    },
    drawerPaper: {
      width: drawerWidth
    },
    active: {
      backgroundColor: '#f4f4f4'
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    toolbar: theme.mixins.toolbar,
    statusbar: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      background: '#3f51b5',
      zIndex: 9999,
      padding: 4,
      color: 'white',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '32px',
      boxSizing: 'border-box'
    }
  }
});
export default function Layout({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [elapsed, setElapsed] = React.useState();
  const [percent, setPercent] = React.useState(0);
  const [running, setRunning] = React.useState(false);

  const sessionRef =
    firestore.collection('logs').doc('scrapingLogs').collection('sessions').orderBy('timestamp', 'desc').limit(1);


  sessionRef.onSnapshot(snap => {
    const data = snap.docs[0].data();
    const percent = ((data.zipCodeCounter / data.totalZipCodes) * 100).toFixed(2);
    if (!isNaN(percent)) {
      setPercent(percent);
      if (parseInt(percent) === 100) {
        setRunning(false);
      } else if (parseInt(percent) >= 0) {
        setRunning(true);
      }

    } else {
      setPercent(0);
      setRunning(true);
    }
  })

  sessionRef.get().then(snap => {

    const startTimestamp = snap.docs[0].data().timestampISO;
    // const momentStartTime = moment.tz(`${new Date(startTimestamp)}`, 'America/Chicago');

    clearInterval(window.sessionTime);

    window.sessionTime = setInterval(() => {

      const t0 = moment(startTimestamp);
      const t1 = moment();
      const diff = t1.diff(t0);
      const duration = moment.duration(diff, "milliseconds");

      // if (duration.seconds() < 0 || duration.minutes() < 0) {
      //   setElapsed('');
      //   return;
      // }

      const seconds = parseInt(duration.seconds()),
        minutes = parseInt(duration.minutes()),
        hours = parseInt(duration.hours()),
        days = parseInt(duration.days());

      let string = 'Running Time: ';
      if (!days && !hours && !minutes) {
        string += `${seconds} seconds`;
      } else if (!days && !hours) {
        string += `${minutes} minutes ${seconds} seconds`;
      } else if (!days) {
        string += `${hours} hours ${minutes} minutes ${seconds} seconds`;
      } else {
        string += `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`;
      }

      setElapsed(string);
    }, 1000);

  });

  const menuItems = [{
    text: 'Home',
    icon: <HomeOutlined color='secondary' />,
    path: '/'
  }, {
    text: 'Services',
    icon: <OutlinedFlag color='secondary' />,
    path: '/services'
  }, {
    text: 'Regions',
    icon: <PlaceOutlined color='secondary' />,
    path: '/regions'
  }, {
    text: 'Cleanup',
    icon: <DeleteOutlineOutlined color='secondary' />,
    path: '/cleanup'
  },
  {
    text: 'Logs',
    icon: <DataUsageOutlined color='secondary' />,
    path: '/logs'
  }];
  return (<>
    <div className={classes.root}>

      {/* App bar */}
      <AppBar
        elevation={0}
        className={classes.appBar}>
        <Toolbar>
          <Typography>Monocle</Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        className={classes.drawer}
        variant='permanent'
        anchor='left'
        // open
        classes={{ paper: classes.drawerPaper }}
      >
        <List>
          {menuItems.map(item => (
            <ListItem
              key={item.text}
              button
              onClick={() => history.push(item.path)}
              className={location.pathname === item.path ? classes.active : null}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}

        </List>
      </Drawer>

      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div>

      <div className={classes.statusbar}>
        {
          running ?
            <span>{elapsed} | {percent} %</span>
            : 'Scraper Status: Stopped'
        }

      </div>
    </div>
  </>
  )
}
