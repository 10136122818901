import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './pages/Home'
import Services from './pages/Services'
import Cleanup from './pages/Cleanup'
import Regions from './pages/Regions'
import Logs from './pages/Logs'

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/cleanup">
            <Cleanup />
          </Route>
          <Route path="/regions">
            <Regions />
          </Route>
          <Route path="/logs">
            <Logs />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;