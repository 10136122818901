import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import LogViewer from '../components/LogViewer'


import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

import { useCollectionData } from 'react-firebase-hooks/firestore';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCgD0y67qvY90yEhS90qAcA1ch_M4i74wo",
    authDomain: "monocle-21465.firebaseapp.com",
    projectId: "monocle-21465",
    storageBucket: "monocle-21465.appspot.com",
    messagingSenderId: "577484496710",
    appId: "1:577484496710:web:e7577bd863dd003f2b4f47",
    measurementId: "G-K9M37VSGH8"
  });
}


const firestore = firebase.firestore();

const providers = [
  {
    name: 'Google',
    value: 'Google'
  },
  {
    name: 'Yelp',
    value: 'Yelp'
  },
  {
    name: 'BBB',
    value: 'BBB'
  },
  {
    name: 'Facebook',
    value: 'Facebook'
  },
  {
    name: 'Normalized',
    value: 'Normalized'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    minHeight: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  viewerContainer: {
    marginTop: 16,
    maxHeight: 'calc(100vh - 190px)',
    overflow: 'auto'
  }

}));

export default function Cleanup() {

  const classes = useStyles();

  const regionsRef = firestore.collection('regions');
  const regionsQuery = regionsRef.orderBy('city');
  const [regions] = useCollectionData(regionsQuery, { idField: 'id' });

  const [showLogs, setShowLogs] = React.useState(false);
  const [logs, setLogs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [logsSubscription, setLogsSubscription] = React.useState(null);
  const [region, setRegion] = React.useState('');
  const [provider, setProvider] = React.useState('');
  const [isProviderSet, setIsProviderSet] = React.useState();
  const [isRegionSet, setIsRegionSet] = React.useState();

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  const deleteSelectedCollection = async () => {
    !region ? setIsRegionSet(false) : setIsRegionSet(true);
    !provider ? setIsProviderSet(false) : setIsProviderSet(true);

    if (!region || !provider) {
      return;
    }

    setShowLogs(true);
    setIsLoading(true);
    try {
      const cleanCollections = (await firebase.functions().httpsCallable('cleanCollections')({
        region: region,
        provider: provider
      })).data;
      setLogs([]);

      // if (logsSubscription) logsSubscription();

      const cleanCollectionsRef = firestore
        .collection('logs').doc('cleanCollections')
        .collection('cleanCollections').doc('items')
        .collection('items').doc(cleanCollections.logRefID)
        .collection('items')
        .orderBy('timestamp')
        .onSnapshot(snap => {
          const data = snap.docs.map(doc => doc.data());
          if (data && data.length > 0) {
            setLogs(data);
          }
          if (data.text === 'Completed') {
            cleanCollectionsRef();
          }
        });

    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }

  return (
    <div>

      <FormControl className={classes.formControl} error={isRegionSet === false}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Region
        </InputLabel>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={region}
          onChange={handleRegionChange}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="" disabled>
            <em>Select a region</em>
          </MenuItem>
          {regions && regions.map(region => (
            <MenuItem key={region.id} value={region.id}>{region.city + ', ' + region.state}</MenuItem>
          ))}
          <MenuItem value="all">
            <em>All</em>
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.formControl} error={isProviderSet === false}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Provider
        </InputLabel>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={provider}
          onChange={handleProviderChange}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="" disabled>
            <em>Select a provider</em>
          </MenuItem>
          {
            providers && providers.map(provider => (
              <MenuItem key={provider.value} value={provider.value}>{provider.name}</MenuItem>
            ))
          }
          <MenuItem value="all">
            <em>All</em>
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Button variant='contained' color='secondary'
          disabled={isLoading}
          onClick={deleteSelectedCollection}
        >Clear</Button>
      </FormControl>

      <div className={classes.viewerContainer}>
        {showLogs ? <LogViewer logs={logs} /> : null}
      </div>

    </div>
  )
}