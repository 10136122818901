import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import firebase from 'firebase/app';
import 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCgD0y67qvY90yEhS90qAcA1ch_M4i74wo",
    authDomain: "monocle-21465.firebaseapp.com",
    projectId: "monocle-21465",
    storageBucket: "monocle-21465.appspot.com",
    messagingSenderId: "577484496710",
    appId: "1:577484496710:web:e7577bd863dd003f2b4f47",
    measurementId: "G-K9M37VSGH8"
  });
}

const firestore = firebase.firestore();


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(2),
  }
}));

export default function Regions() {

  const classes = useStyles();
  // const theme = useTheme();


  const [selectedAvailableRegion, setSelectedAvailableRegion] = useState(null);
  const [state, setState] = useState({ switchState: {} });
  const [open, setOpen] = React.useState(false);
  const [availableRegions, setAvailableRegions] = useState([]);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [alertText, setAlertText] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState('');
  const [detailsOpened, setDetailsOpened] = React.useState(false);
  const [detailsRegion, setDetailsRegion] = React.useState('');



  const regionsRef = firestore.collection('regions');
  const regionsQuery = regionsRef.orderBy('state', 'asc');
  const [regions] = useCollectionData(regionsQuery, { idField: 'id' });

  const handleToggleChange = (region, i) => (event) => {
    const regionRef = firestore.collection('regions').doc(region.id);
    let isEnabled;
    if (event.target.checked) {
      isEnabled = true;
    }
    if (!event.target.checked) {
      isEnabled = false;
    }

    regionRef.update({
      isEnabled: isEnabled,
    });

    const list = Object.assign({}, state.switchState);

    list['switch-' + i] = event.target.checked;

    setState({
      switchState: list
    })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  }

  const fetchRegions = async (e) => {
    const searchString = e.target.value;
    if (searchString.length > 2) {
      // TODO: Use Algolia instead
      const regionsRef = firestore.collection('availableRegions').where('city', '==', searchString);
      const regions = (await regionsRef.get()).docs;

      const _regions = [];
      if (regions.length > 0) {
        regions.forEach(region => {
          const data = region.data();
          _regions.push(data);
        });
      }

      setAvailableRegions(_regions);
    }
  }

  const availableRegionSelected = async (value) => {
    // check if already added
    if (!value) {
      return;
    }
    const ref = firestore.collection('regions').where('city', '==', value.city).where('state', '==', value.state);
    if (!(await ref.get()).empty) {
      setAlertSeverity('error');
      setAlertText(`${value.city}, ${value.state} already exists.`)
      setOpenSnackBar(true);
    } else {
      const id = `${value.city}-${value.state}`.replace(' ', '-').toLowerCase();
      firestore.collection('regions').add({
        city: value.city,
        state: value.state,
        isEnabled: false,
        id: id
      });
      setAlertSeverity('success');
      setAlertText(`Added ${value.city}, ${value.state} successfully`);
      setOpenSnackBar(true);
      setOpen(false);
    }
  }

  const restartScraping = (region) => event => {
    firestore.collection('regions').doc(region.id).update({ mostRecentScrape: '' });
    // reset scrape date on all zip codes
    firestore.collection('zipCodes')
      .where('mixedCity', '==', region.city)
      .where('stateCode', '==', region.state)
      .get()
      .then(async snap => {
        const batch = firestore.batch();

        for (const doc of snap.docs) {
          const zipCodeData = doc.data();
          const zipCodeQueueDocs = (await firestore.collection('zipCodesQueue').where('zipCode', '==', zipCodeData.zipCode).get()).docs;
          zipCodeQueueDocs.forEach(doc => {
            batch.update(doc.ref, {
              lastScrapedDate: ''
            });
          });
        }
        batch.commit();
      })
  }

  const showDetails = (region) => {
    setDetailsOpened(true);
    setDetailsRegion(region);
  }

  const closeScrapingDetails = () => {
    setDetailsOpened(false);
  }

  useEffect(() => {

    const switchState = {};
    if (regions && regions.length > 0) {
      regions.forEach((item, index) => {
        switchState['switch-' + index] = item.isEnabled === true;
      });
      setState({
        switchState: switchState
      });

    }

    return () => {
      // cleanup
    }
  }, [regions])

  return (
    <div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Number of zipcodes</TableCell>
              <TableCell>Scraping Completion</TableCell>
              <TableCell>Last Scraped On</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Scraping Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {regions && regions.map((region, i) => (
              <TableRow key={region.id}>
                <TableCell component="th" scope="row">
                  {region.city}
                </TableCell>
                <TableCell>{region.state}</TableCell>
                <TableCell>{region.numberOfZipCodes}</TableCell>
                <TableCell>
                  <div style={{ height: 56, width: 56 }}>{
                    !isNaN(region.numberOfScrapedZipCodes) ?
                      <CircularProgressbar
                        value={((region.numberOfScrapedZipCodes / region.numberOfZipCodes) * 100).toFixed(2)} text={`${((region.numberOfScrapedZipCodes / region.numberOfZipCodes) * 100).toFixed(0)}%`}
                        background
                        backgroundPadding={6}
                        styles={buildStyles({
                          backgroundColor: "#3e98c7",
                          textColor: "#fff",
                          textSize: 28,
                          pathColor: "#fff",
                          trailColor: "transparent"
                        })}
                      />
                      : null
                  }
                  </div>
                </TableCell>
                <TableCell>
                  {new Date(region.mostRecentScrape).toString() !== 'Invalid Date' ?
                    new Date(region.mostRecentScrape).toLocaleString()
                    :
                    null}
                  <br />
                  <br />
                  {

                    (new Date(region.mostRecentScrape)).toString() !== 'Invalid Date' ?
                      <Button variant="contained" color="secondary" onClick={restartScraping(region)}>restart</Button> : null
                  }

                  {/* <Button variant="contained" color="secondary" onClick={restartScraping(region)}>restart</Button> */}

                </TableCell>
                <TableCell>
                  <Switch
                    // checked={item.enabled === true}
                    checked={state.switchState['switch-' + i] === true}
                    onChange={handleToggleChange(region, i)}
                    name={region.id}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                <TableCell>
                  <Button onClick={e => showDetails(region)}>Details</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add new region</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Search and enter a region (city).
            <br />
            <em>Note: City name should start with an uppercase letter and the complete city name should be entered.</em>
          </DialogContentText>
          <Autocomplete
            id="combo-box-demo"
            options={availableRegions}
            getOptionLabel={(option) => option.city + ', ' + option.state}
            style={{ width: 300 }}
            onChange={(event, value) => setSelectedAvailableRegion(value)}
            renderInput={(params) => <TextField {...params} label="Search for a city" variant="outlined" onInput={fetchRegions} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => availableRegionSelected(selectedAvailableRegion)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Fab color="secondary" aria-label="add" className={classes.fab} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>

      <Dialog open={detailsOpened} onClose={closeScrapingDetails} aria-labelledby="scraping-details" fullScreen>
        <DialogTitle id="scraping-details">Scraping Details for {detailsRegion.city}, {detailsRegion.state}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TableContainer >
              <Table className={classes.table} >
                <TableHead>
                  <TableRow>
                    <TableCell>ZIP Code</TableCell>
                    <TableCell>Google / Matches</TableCell>
                    <TableCell>Yelp</TableCell>
                    <TableCell>BBB</TableCell>
                    <TableCell>Facebook</TableCell>
                    <TableCell>Normalized</TableCell>
                    <TableCell>Matches?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow >
                      <TableCell component="th" scope="row">
                      
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                       
                      </TableCell>
                      <TableCell>
                      </TableCell>
                      <TableCell>
                    
                      </TableCell>
                      <TableCell>
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeScrapingDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
        <MuiAlert onClose={handleSnackBarClose} elevation={6} variant="filled" severity={alertSeverity} >
          {alertText}
        </MuiAlert>
      </Snackbar>

    </div>
  )


}
