import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: '#333',
      padding: 16,
      borderRadius: 8
    },
    line: {
      marginBottom: 8,
      display: 'grid',
      gridTemplateColumns: '48px auto 100px',
      gap: 8
    },
    number: {
      color: '#FFFDD0',
      userSelect: 'none'
    },
    text: {
      color: '#FFFDF0'
    },
    error: {
      backgroundColor: 'red',
      marginBottom: 8,
      display: 'grid',
      gridTemplateColumns: '48px auto 100px',
      gap: 8
    }
  }
});
export default function LogViewer({ logs }) {
  const classes = useStyles();

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [logs]);


  return (<>
    <div className={classes.root} >
      {logs && logs.length > 0 &&
        // logs.slice((logs.length - 5)
        Object.values(logs)
          .slice((Object.values(logs).length - 200))
          .map(log => (
            <div key={log.lineNumber} className={log.type === 'error' ? classes.error : classes.line}>
              <span className={classes.number}>{log.lineNumber}</span>
              {
                log.type === 'image' ?
                  <a target='blank' href={log.text}><img width={300} src={log.text} alt='screenshot' /></a>
                  :
                  <span className={classes.text}>{log.text}</span>

              }
              <span className={classes.text}>{new Date(log.timestamp).toLocaleTimeString()}</span>
            </div>
          ))}
      <div ref={messagesEndRef} />
    </div>
  </>
  )


}
